import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/common/layout.tsx";
import { SEO } from '../../common/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title={props.pageContext.frontmatter.title} mdxType="SEO" />
    <h1><span children={props.pageContext.frontmatter.title} /></h1>
    <hr></hr>
    <p>{`The whole system is compromised of these microservices:`}</p>
    <ul>
      <li parentName="ul">{`Lambda: Fetch new song titles from YouTube`}</li>
      <li parentName="ul">{`DynamoDB: Store YouTube channels to check daily`}</li>
      <li parentName="ul">{`SQS: Store song titles`}</li>
      <li parentName="ul">{`Lambda: Search song titles from SQS on Spotify and add it to our playlist`}</li>
      <li parentName="ul">{`DynamoDB: Store blacklisted tokens for song title normalization`}</li>
      <li parentName="ul">{`Lambda: Keep our Spotify token alive`}</li>
      <li parentName="ul">{`Lambda: Fetch playlist tracks`}</li>
      <li parentName="ul">{`DynamoDB: Cache playlist tracks`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`The goal: fully automated system that requires no human intervention`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      